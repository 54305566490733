import Chart from 'chart.js/auto'
import PocketBase from 'pocketbase';

const pb = new PocketBase(location.origin);

(async function () {
    const calculator = document.getElementById('calculator');
    const chart = document.getElementById('chart');
    const chartLoader = document.getElementById('chartLoader');
    const eur = document.getElementById('eur');
    const brezen = document.getElementById('brezen');
    const ppb = document.getElementById('ppb');

    const prices = await pb.collection('calculator_prices').getFullList({
        sort: 'date',
    });

    let preis;

    for (let i = prices.length - 1; i >= 0; i--) {
        const price = prices[i];
        if (i === prices.length - 1) {
            preis = price.average_ppb;
        }
        ppb.appendChild(
            new Option(
                price.average_ppb.toFixed(2) + ' EUR - ' + price.date.substring(0, 4),
                price.average_ppb
            )
        );
    }

    if (prices.length === 0) {
        ppb.appendChild(
            new Option(
                'Keine Preise verfügbar',
                null
            )
        );
    }

    if (prices.length > 0) {
        calculator.classList.remove('hidden');
        eur.attributes.removeNamedItem('disabled');
        brezen.attributes.removeNamedItem('disabled');
        ppb.attributes.removeNamedItem('disabled');
    }
    ppb.removeChild(ppb.children[0]);

    let lastChanged = 'eur';

    ppb.addEventListener('change', (e) => {
        preis = e.target.value;
        if (lastChanged != 'eur') {
            brezen.value = Number(parseFloat(eur.value / preis).toFixed(2));
        } else {
            eur.value = Number(parseFloat(brezen.value * preis).toFixed(2));
        }
    })

    eur.addEventListener('keyup', () => {
        let value = eur.value.replace(',', '.');

        if (!value || parseFloat(value) === 0 || isNaN(parseFloat(value))) {
            brezen.value = null;
            return;
        }

        let res = Number(parseFloat(value / preis).toFixed(2));

        if (isNaN(res)) {
            eur.value = null;
            return;
        }

        brezen.value = Number(parseFloat(value / preis).toFixed(2));
        lastChanged = 'brezen'
    })

    brezen.addEventListener('keyup', () => {
        let value = brezen.value.replace(',', '.');

        if (!value || parseFloat(value) === 0 || isNaN(parseFloat(value))) {
            eur.value = null;
            return;
        }

        let res = Number(parseFloat(value * preis).toFixed(2));

        if (isNaN(res)) {
            eur.value = null;
            return;
        }

        eur.value = res;
        lastChanged = 'eur'
    })


    const datapoints = [];
    const labels = [];
    const ctx = document.getElementById('myChart').getContext('2d');

    for (let i = 0; i < prices.length; i++) {
        labels.push(prices[i].date.substring(0, 4));
        datapoints.push(prices[i].average_ppb)
    }

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Brezenpreis Druchschnitt in Cent',
                data: datapoints,
                borderColor: '#c06632',
                fill: false,
                cubicInterpolationMode: 'linear',
                tension: 0.0
            }
        ]
    };

    const config = {
        type: 'line',
        data: data,
        responsive: true,
        options: {
            maintainAspectRatio: false,
            animation: false,
            plugins: {
                tooltip: {
                    enabled: true
                },
                legend: {
                    display: false
                },
            },
            interaction: {
                intersect: false,
            },
            scales: {
                x: {
                    display: true,
                    text: 'Jahr',
                },
                y: {
                    display: true,
                    suggestedMin: datapoints[0],
                    suggestedMax: datapoints[datapoints.length - 1],
                    text: 'EUR',
                }
            }
        },
    };

    new Chart(ctx, config)
    if (prices.length > 0) {
        chart.classList.remove('hidden');
        chartLoader.classList.add('hidden');
    }
})();


console.log('%c 🥨 Vorsicht, hier kann man viel verSEMMELn!!! 🥨 ', 'font-weight: bold; font-size: 50px;color: red;');
